import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:作业量统计
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17195
 */
export function jobAmountStatisticsQuery(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/job_amount_statistics/query', data, {
    params,
  });
}

/**
 * @description:导出作业量报表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17203
 */
export function jobAmountStatisticsExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/job_amount_statistics/export', data, { fileName: '作业量报表', extension: 'xlsx' });
}
