import { FormType } from '@/constant/form';
import { YMDHM } from '@/constant/timeFormat';
import { TASK_TYPE } from '@/views/task-center/task-management/constant';

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}
export const helpProp = ['wholeUnitAmount', 'wholeBoxAmount'];

export const TABLECOLUMN = (zoneTemperature) => [{
  label: '序号',
  prop: 'index',
  width: 50,
}, {
  label: '货主编码',
  prop: 'shipperCode',
  minWidth: 120,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 120,
}, {
  label: '温层',
  prop: 'temperatureLayer',
  minWidth: 80,
  formatter: (row) => filterLabel(row.temperatureLayer, zoneTemperature),
}, {
  label: '任务类型',
  prop: 'taskType',
  minWidth: 100,
  formatter: (row) => filterLabel(row.taskType, TASK_TYPE),
}, {
  label: '整单位数量',
  prop: 'wholeUnitAmount',
  minWidth: 120,
}, {
  label: '整箱数量',
  prop: 'wholeBoxAmount',
  minWidth: 120,
}, {
  label: '拆零数量',
  prop: 'zeroBoxAmount',
  minWidth: 120,
}];

export const serchFields = (zoneTemperature) => [
  {
    label: '任务生成时间',
    prop: 'taskStartTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      format: YMDHM,
    },
  }, {
    label: '货主',
    prop: 'shipperId',
  }, {
    label: '任务类型',
    prop: 'taskType',
    component: FormType.SELECT,
    options: TASK_TYPE,
  },
  {
    label: '温层',
    prop: 'temperatureLayer',
    component: FormType.SELECT,
    options: zoneTemperature,
  },
  {
    label: '任务完结时间',
    prop: 'taskCompleteTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      format: YMDHM,
    },
  },
];
