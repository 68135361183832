<template>
  <div>
    <pl-block>
      <PlForm
        v-model="searchQuery"
        mode="search"
        :fields="serchFields(zoneTemperature)"
        @submit="handleQuery"
        @reset="handleQuery"
      >
        <template #shipperId>
          <nh-shipper-selector
            v-model:shipperId="searchQuery.shipperId"
          />
        </template>
      </PlForm>
    </pl-block>

    <pl-block>
      <nh-pro-table-header>
        <template #headerRight>
          <nh-button
            type="primary"
            size="small"
            plain
            :loading="loading.handleExport"
            :track="{
              trackName: '报表中心/作业量报表/[导出]'
            }"
            @click="handleExport"
          >
            导出
          </nh-button>
        </template>
      </nh-pro-table-header>
      <el-table v-loading="loading.getTableData" :data="tableData">
        <template #empty>
          <el-empty description="暂无数据" />
        </template>
        <el-table-column
          v-for="(item, index) in TABLECOLUMN(zoneTemperature)"
          :key="index"
          :label="item.label"
          :formatter="item.formatter"
          :min-width="item.minWidth"
          :prop="item.prop"
          :type="item.type"
        >
          <template v-if=" helpProp.includes(item.prop)" #header>
            {{ item.label }}
            <nh-help-popover v-if="item.prop === 'wholeUnitAmount'" title="说明：">
              <div>货品数量为1箱2袋，换算率为10；</div>
              <div>则整单位数量为1.2</div>
            </nh-help-popover>
            <nh-help-popover v-if="item.prop === 'wholeBoxAmount'" title="说明：">
              <div>货品数量为1箱2袋，换算率为10；</div>
              <div>则整箱数量为1， 拆零数量为2；</div>
            </nh-help-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <nh-pagination
          class="margin-top_normal"
          v-bind="pagination"
          @change="handlePaginationChange"
        />
      </div>
    </pl-block>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from '@/utils/moment';
import {
  TABLECOLUMN, serchFields, helpProp,
} from './fileds';
import {
  jobAmountStatisticsQuery,
  jobAmountStatisticsExport,
} from './api';

export default {
  name: 'InWarehouseDetails',
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      helpProp,
      tableData: [],
      searchQuery: {
        taskStartTime: [moment.startOf(moment.add(new Date(), { days: -1 })),
          moment.ensOf(moment.add(new Date(), { days: -1 }))],
        shipperId: null,
        taskType: null,
        temperatureLayer: null,
        taskCompleteTime: [],
      },
      selectIds: [],
      loading: {
        getTableData: false,
        handleExport: false,
      },
    };
  },
  computed: {
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.tableData = [];
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await jobAmountStatisticsQuery(pagination, params);
      let totalWholeUnitAmount = 0;
      let totalWholeBoxAmount = 0;
      let totalZeroBoxAmount = 0;
      this.tableData = resp.records.map(((item, index) => {
        totalWholeUnitAmount += item.wholeUnitAmount * 1000;
        totalWholeBoxAmount += item.wholeBoxAmount;
        totalZeroBoxAmount += item.zeroBoxAmount;
        return {
          ...item,
          index: index + 1,
        };
      }));
      if (this.searchQuery.taskType && (this.searchQuery.taskStartTime.length > 0
      || this.searchQuery.taskCompleteTime.length > 0)) {
        this.tableData.unshift({
          index: '合计',
          wholeUnitAmount: totalWholeUnitAmount / 1000,
          wholeBoxAmount: totalWholeBoxAmount,
          zeroBoxAmount: totalZeroBoxAmount,
        });
      }
      this.pagination.total = resp.total;
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.getTableData();
    },
    buildParams() {
      const [taskStartTimeBegin, taskStartTimeEnd] = this.searchQuery.taskStartTime || [];
      const [taskCompleteTimeBegin, taskCompleteTimeEnd] = this.searchQuery.taskCompleteTime || [];
      return {
        ...this.searchQuery,
        taskStartTimeBegin,
        taskStartTimeEnd,
        taskCompleteTimeBegin,
        taskCompleteTimeEnd,
      };
    },
    async handleExport() {
      const data = this.buildParams();
      await jobAmountStatisticsExport(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

</style>
